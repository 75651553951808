<template lang="">
  <div class="w-100 border-secondary rounded">
    <div
      v-if="price?.trip"
      class="font-weight-bolder d-flex-center text-18px p-50 text-warning"
    >
      {{ price?.trip }}
    </div>

    <div class="section-table">
      <b-table
        v-if="price?.paxFare?.length"
        centered
        responsive
        bordered
        class="mb-0"
        thead-class="text-dark align-middle text-nowrap border-0"
        tbody-class="text-dark align-middle text-nowrap text-right border-0"
        :fields="tableColumnsPaxFareInfoTicket"
        :items="price.paxFare"
      >
        <template
          v-for="(column, indexColumn) in tableColumnsPaxFareInfoTicket"
          #[`head(${column.key})`]
        >
          <div
            :key="indexColumn"
            class="fw-600 text-14px"
            :class="`${column.key === 'passenger' ? 'text-left' : 'text-right'}`"
          >
            {{ $t(`reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.${column.key}`) }}
          </div>
        </template>
        <template #cell(passenger)="data">
          <div class="text-left font-weight-bolder">
            <span>{{ $t(`reservation.${data.item.paxType}`) }} x{{ data.item.amountPassenger }}</span>
          </div>
        </template>
        <template #cell(netPrice)="data">
          <div class="text-right font-weight-bolder">
            {{ formatCurrency(Math.floor(data.item.netPrice / data.item.amountPassenger)) }}
          </div>
        </template>
        <template #cell(tax)="data">
          <div class="text-right font-weight-bolder">
            {{ formatCurrency(Math.floor(data.item.tax / data.item.amountPassenger)) }}
          </div>
        </template>
        <template #cell(total)="data">
          <div class="text-right font-weight-bolder color-theme">
            {{ formatCurrency(Math.floor(data.item.total / data.item.amountPassenger)) }}
          </div>
        </template>
      </b-table>

      <b-table
        v-else-if="price?.fareInfoTicket"
        centered
        responsive
        :borderless="false"
        class="mb-0"
        thead-class="text-dark align-middle text-nowrap text-right border-0"
        tbody-class="text-dark align-middle text-nowrap text-right border-0"
        :fields="tableColumnsFareInfoTicket"
        :items="price.fareInfoTicket"
      >
        <template
          v-for="(column, indexColumn) in tableColumnsFareInfoTicket"
          #[`head(${column.key})`]
        >
          <span
            :key="indexColumn"
            class="fw-600 text-14px"
          >
            {{ $t(`reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.${column.key}`) }}
          </span>
        </template>
        <template #cell(passenger)="data">
          <div class="text-right font-weight-bolder">
            <span>{{ data.item.passenger }}</span>
          </div>
        </template>
        <template #cell(netPrice)="data">
          <div class="text-right font-weight-bolder">
            {{ formatCurrency(Math.floor(data.item.netPrice)) }}
          </div>
        </template>
        <template #cell(tax)="data">
          <div class="text-right font-weight-bolder">
            {{ formatCurrency(Math.floor(data.item.tax)) }}
          </div>
        </template>
        <template #cell(total)="data">
          <div class="text-right font-weight-bolder color-theme">
            {{ formatCurrency(Math.floor(data.item.total)) }}
          </div>
        </template>
      </b-table>

      <!-- Tổng 1 bảng -->
      <HStack
        end
        class="text-right text-danger px-1 py-50"
      >
        <span>
          {{ $t('ticket.priceTable.total') }}:
        </span>
        <div class="text-18px fw-700">
          {{ formatCurrency(Math.floor(price.total)) }}
        </div>
      </HStack>
    </div>
  </div>
</template>
<script>
import { computed, toRef } from '@vue/composition-api'
import { BTable } from 'bootstrap-vue'

import { formatCurrency } from '@/@core/utils/filter'

export default {
  components: {
    BTable,
  },
  props: {
    price: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const price = toRef(props, 'price')
    const tableColumnsPaxFareInfoTicket = computed(() => ['passenger', 'netPrice', 'tax', 'total'].map(item => ({
      key: item,
      label: item,
    })))

    const tableColumnsFareInfoTicket = computed(() => {
      const arr = price.value.source === 'F1' ? ['passenger', 'total'] : ['passenger', 'netPrice', 'tax', 'total']
      return arr.map(item => ({
        key: item,
        label: item,
      }))
    })
    return {
      tableColumnsPaxFareInfoTicket,
      tableColumnsFareInfoTicket,
      formatCurrency,
    }
  },
}
</script>
<style lang="">

</style>
