var render = function () {
  var _vm$price, _vm$price2, _vm$price3, _vm$price3$paxFare, _vm$price4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 border-secondary rounded"
  }, [(_vm$price = _vm.price) !== null && _vm$price !== void 0 && _vm$price.trip ? _c('div', {
    staticClass: "font-weight-bolder d-flex-center text-18px p-50 text-warning"
  }, [_vm._v(" " + _vm._s((_vm$price2 = _vm.price) === null || _vm$price2 === void 0 ? void 0 : _vm$price2.trip) + " ")]) : _vm._e(), _c('div', {
    staticClass: "section-table"
  }, [(_vm$price3 = _vm.price) !== null && _vm$price3 !== void 0 && (_vm$price3$paxFare = _vm$price3.paxFare) !== null && _vm$price3$paxFare !== void 0 && _vm$price3$paxFare.length ? _c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "centered": "",
      "responsive": "",
      "bordered": "",
      "thead-class": "text-dark align-middle text-nowrap border-0",
      "tbody-class": "text-dark align-middle text-nowrap text-right border-0",
      "fields": _vm.tableColumnsPaxFareInfoTicket,
      "items": _vm.price.paxFare
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsPaxFareInfoTicket, function (column, indexColumn) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn() {
          return [_c('div', {
            key: indexColumn,
            staticClass: "fw-600 text-14px",
            class: "".concat(column.key === 'passenger' ? 'text-left' : 'text-right')
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.".concat(column.key))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(passenger)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-left font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.item.paxType))) + " x" + _vm._s(data.item.amountPassenger))])])];
      }
    }, {
      key: "cell(netPrice)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Math.floor(data.item.netPrice / data.item.amountPassenger))) + " ")])];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Math.floor(data.item.tax / data.item.amountPassenger))) + " ")])];
      }
    }, {
      key: "cell(total)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right font-weight-bolder color-theme"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Math.floor(data.item.total / data.item.amountPassenger))) + " ")])];
      }
    }], null, true)
  }) : (_vm$price4 = _vm.price) !== null && _vm$price4 !== void 0 && _vm$price4.fareInfoTicket ? _c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "centered": "",
      "responsive": "",
      "borderless": false,
      "thead-class": "text-dark align-middle text-nowrap text-right border-0",
      "tbody-class": "text-dark align-middle text-nowrap text-right border-0",
      "fields": _vm.tableColumnsFareInfoTicket,
      "items": _vm.price.fareInfoTicket
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsFareInfoTicket, function (column, indexColumn) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn() {
          return [_c('span', {
            key: indexColumn,
            staticClass: "fw-600 text-14px"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.".concat(column.key))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(passenger)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_c('span', [_vm._v(_vm._s(data.item.passenger))])])];
      }
    }, {
      key: "cell(netPrice)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Math.floor(data.item.netPrice))) + " ")])];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Math.floor(data.item.tax))) + " ")])];
      }
    }, {
      key: "cell(total)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right font-weight-bolder color-theme"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Math.floor(data.item.total))) + " ")])];
      }
    }], null, true)
  }) : _vm._e(), _c('HStack', {
    staticClass: "text-right text-danger px-1 py-50",
    attrs: {
      "end": ""
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('ticket.priceTable.total')) + ": ")]), _c('div', {
    staticClass: "text-18px fw-700"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Math.floor(_vm.price.total))) + " ")])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }